<template>
  <ion-card
    class="quizzes-card clickable-item m-0"
    @click="openQuizzesModal"
    :class="{ 'quizzes-now-border': get(quiz, 'type') === 'now' }"
  >
    <div class="d-flex position-relativ flex-column">
      <ion-badge v-if="get(quiz, 'type') === 'now'" class="quiz-day-badge m-1">
        Quiz Day {{ get(quiz, 'action_object.next_day') }}
      </ion-badge>
      <ion-badge
        v-else
        class="badge m-1"
        :class="{ 'badge-width': (get(quiz, 'quiz.category') || get(quiz, 'category')) === 'wb' }"
        >{{ categoriesByVal[get(quiz, 'category')].name }}</ion-badge
      >
      <div class="w-100 position-relative">
        <ion-badge class="ques-badge d-flex align-items-center">
          <ion-icon class="chat-icon" :icon="chatbubblesOutline" />
          <span class="mx-1">{{
            (get(quiz, 'type') === 'now' ? get(quiz, 'target.question_count') : get(quiz, 'question_count')) <= 99
              ? get(quiz, 'type') === 'now'
                ? get(quiz, 'target.question_count')
                : get(quiz, 'question_count')
              : get(quiz, 'type') === 'now'
              ? `${get(quiz, 'target.question_count')}+`
              : `${get(quiz, 'question_count')}+`
          }}</span>
        </ion-badge>
        <div class="d-flex align-items-center text-center justify-content-center title-bg">
          <div class="title m-1" :class="{ 'title-position': get(quiz, 'title.length') >= 35 }">
            {{
              get(quiz, 'type') === 'now'
                ? truncateEasy(get(quiz, 'target.title'), isMobSmallScreen ? 22 : 32)
                : truncateText(get(quiz, 'title'), isMobSmallScreen ? 30 : 35)
            }}
          </div>
        </div>
      </div>
      <div v-if="get(quiz, 'type') === 'now'" class="d-flex flex-column">
        <div class="d-flex align-items-center justify-content-center">
          <img
            v-character-image
            class="char-img mx-1"
            :src="get(quiz, 'action_object.character.info.cropProfilePicture')"
          />
          <span class="char-name">{{ get(quiz, 'action_object.character.info.name') }}</span>
        </div>
        <div class="d-flex btns-wrap">
          <ion-button class="continue-btn" @click="openContQuizModal">Continue</ion-button>

          <ion-button color="transparent" class="skip-btn" @click="skip">Skip</ion-button>
        </div>
      </div>
      <div v-else class="mt-auto p-1 m-1">
        <ion-button class="day-btn w-100" @click.stop="openQuizzesModal"> Start </ion-button>
      </div>
    </div>
  </ion-card>
</template>
<script setup lang="ts">
import { truncateText } from '@/shared/utils/string';
import { chatbubblesOutline } from 'ionicons/icons';
import { truncateEasy } from '@/shared/utils/string';
import constants from '~/shared/statics/constants';
import { markAsSkip } from '@/shared/actions/notifications';
import { mainStore } from '~/shared/pinia-store/main';
const { isMobSmallScreen } = useWindowSize();
const props = defineProps({
  quiz: { type: Object, default: {} },
});
const { dark } = mainStore();
const categoriesByVal = keyBy(constants.quizCategories, 'value');
const router = useRouter();
const quiz = toRef(props, 'quiz');
const openQuizzesModal = () => {
  if (get(quiz.value, 'type') === 'quiz') {
    router.push({ name: 'quizzes', query: { quizId: quiz.value.id } });
  }
};

const emits = defineEmits(['skip']);
const skip = async () => {
  await markAsSkip(quiz.value.id);
  emits('skip');
};
const openContQuizModal = () => {
  router.push({
    name: 'quizzes',
    query: {
      quizId: get(quiz.value, 'target.id'),
      logId: get(quiz.value, 'action_object.id'),
      charId: get(quiz.value, 'action_object.character.id'),
    },
  });
};

const getDefaultClr = computed(() => {
  const clr = dark.value ? '#17074C' : '#f0f0f0';
  return clr;
});

const getBadgeColor = computed(() => {
  if (get(quiz.value, 'type') === 'quiz') {
    return categoriesByVal[quiz.value.category].color;
  }
});
</script>
<style lang="sass" scoped>
.skip-btn
  font-size: 10px
  color: #7050b7
.dark .skip-btn
  color: white
.btns-wrap
  margin-top: -4px
  padding: 0px 8px 0px 8px
.quizzes-now-border
  border: 2px solid transparent !important
  background: linear-gradient(0deg, v-bind(getDefaultClr),v-bind(getDefaultClr)), linear-gradient(90deg, rgba(6, 247, 233, 0.7) 0%, #00AAE0 100%) !important
  background-clip: padding-box, border-box !important
  background-origin: padding-box, border-box !important
.char-img
  height: 15px !important
  width: 15px !important
  border-radius: 20px
.dark .char-name
  color: white
.char-name
  color: black
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  font-size: 14px
.continue-btn
  border-radius: 15px
  width: 100%
  font-size: 14px
  height: 30px
  --background: linear-gradient(0deg, #00AAE0, #00AAE0), linear-gradient(90deg, rgba(6, 247, 233, 0.7) 0%, rgba(6, 131, 247, 0) 100%)
  background: linear-gradient(0deg, #00AAE0, #00AAE0), linear-gradient(90deg, rgba(6, 247, 233, 0.7) 0%, rgba(6, 131, 247, 0) 100%)
  text-transform: none
.badge-width
  min-width: 107px !important
.badge
  line-height: 14px
  background: #011c38
  border-radius: 10px
  max-width: 100%
  min-width: 104px
  color: v-bind(getBadgeColor)
  border: 2px solid v-bind(getBadgeColor)
  height: 22px
.quiz-day-badge
  line-height: 14px
  background: #011c38
  border-radius: 10px
  max-width: 100%
  min-width: 104px
  height: 22px
  background: linear-gradient(0deg, #00AAE0, #00AAE0), linear-gradient(90deg, rgba(6, 247, 233, 0.7) 0%, rgba(6, 131, 247, 0) 100%)
.ques-badge
  top: -1px
  position: absolute
  left: 10px
  font-size:11px
  background: #011c38
  border-radius: 10px
  max-width: 100%
  min-width: 41px
  height: 20px
.day-btn
  --background: linear-gradient(90deg, #AE38E5 0%, rgba(174, 56, 229, 0) 100%), #7050B7
  --border-radius: 20px
  color: white
  font-weight: bold
  text-transform: none
  height: 37px
.title-bg
  background: linear-gradient(180deg, #FFDFC8 0%, rgba(255, 223, 200, 0) 100%), #EABEFF
  height: 91px
  margin: 0px 0.5rem 0px 0.5rem !important
  width: auto
  border-radius: 17px
.dark .title-bg
    background: linear-gradient(180deg, #FFDFC8 0%, rgba(255, 223, 200, 0) 100%), #EABEFF !important
.title
  color: #431C89 !important
  font-size: 13px
  font-weight: bold
.title-position
  margin-top: 26px !important

.quizzes-card
  max-width: 145px !important
  min-width: 145px !important
  min-height: 180px
  max-height: 180px
  background: transparent
  border: 2.5px solid #7050B7
  border-radius: 12px
</style>
